const messages = {
  tr: {
    cancel: 'İptal',
    send: 'Gönder',
    sending: 'Gönderiliyor...',
    name: 'Name',
    okey: 'Tamam',
    speciality: 'Uzmanlık',
    city: 'Şehir',
    page_language: 'Sayfa dilini değiştirin',
    type_your_question: 'Sorunuzu bu alana yazabilirsiniz.',
    this_event_is_not_started: 'Etkinlik henüz başlamadı.',
    this_event_completed: 'Etkinlik sona erdi.',
    registration_required:
      'Bu event için kayıt olmak zorunludur. Kayıt sayfasına yönlendiriliyorsunuz',
  },
};

export default messages;

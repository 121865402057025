const messages = {
  de: {
    cancel: 'Stornieren',
    send: 'Senden',
    sending: 'Senden...',
    name: 'Name',
    okey: 'Okey',
    page_language: 'Website-Sprache',
    type_your_question: 'Schreibe deine Frage',
    this_event_is_not_started: 'Diese Veranstaltung hat noch nicht begonnen.',
    this_event_completed: 'Diese Veranstaltung wurde abgeschlossen.',
    registration_required: 'Für diese Veranstaltung ist eine Anmeldung erforderlich.',
  },
};

export default messages;

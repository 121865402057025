const messages = {
  fr: {
    cancel: 'Annuler',
    send: 'Envoyer',
    sending: 'Envoi...',
    name: 'Prénom',
    last_name: 'Nom De Famille',
    okey: 'Okey',
    email: 'Email',
    event_password: 'Mot de passe',
    registration: 'Enregistrement',
    city: 'Ville',
    'country/region': 'Pays De Résidence',
    'zip/postal_code': 'Code Postal',
    'state/province': 'état',
    phone: 'Téléphone',
    address: 'Adresse',
    speciality: 'Spécialité',
    organization: 'Organisation',
    job_title: 'Aucun événement trouvé',
    please_wait: 'S’il vous plaît, attendez',
    no_event_found: 'Aucun événement trouvé',
    join_now: 'Adhérer maintenant',
    page_language: 'Langue du site Web',
    type_your_question: 'Veuillez taper votre question',
    this_event_is_not_started: 'Cet événement n’a pas encore commencé',
    this_event_completed: 'Cet événement est terminé',
    registration_required:
      'L’inscription est obligatoire pour cet événement. Redirection vers la page d’inscription',
    thankyou_registration: 'Merci d’avoir enregistré notre webcast!',
    link_sent: 'Un lien privé pour le webcast a été envoyé à votre adresse e-mail',
  },
};

export default messages;
